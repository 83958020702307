
import { mixins, Options } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import WithModel from '../mixins/withModel'

@Options({
    components: {
    }
})
export default class inputPassword extends mixins(WithModel) {

    @Prop({
        default: 0
    }) tabIndex;

    @Prop({
        default: ""
    }) placeholderKey;

    @Prop({
        default: ""
    }) placeholderValue;

   @Prop({
        default: ""
    }) placeholderPrevText: string;

    @Prop({
        default: ""
    }) placeholderAfterText: string;

    @Prop({
        default: false
    }) readonly: boolean;

    showPassword: boolean = false;

    changeShowPassword(){
       this.showPassword = !this.showPassword;
        
        if(this.showPassword)
            (<any>this.$refs.input).type = "text";
        else
            (<any>this.$refs.input).type = "password";

    }
}
