import { vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createBlock("input", {
    ref: "range",
    type: "range",
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.localValue = $event)),
    style: 'background-size:' + (_ctx.localValue - _ctx.min) * 100 / (_ctx.max - _ctx.min) + '% 100%',
    readonly: _ctx.readonly,
    tabindex: _ctx.tabIndex,
    min: _ctx.min,
    max: _ctx.max,
    step: _ctx.step
  }, null, 12, ["readonly", "tabindex", "min", "max", "step"])), [
    [_vModelText, _ctx.localValue]
  ])
}