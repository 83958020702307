import { h } from 'vue';
import { RouteRecordRaw, RouterView } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/events',
            component: { render: () => h(RouterView) },
            children: [
                {
                    path: '/events',
                    name: 'events',
                    component: () => import("./views/events.vue")
                }
            ]
        }
    ];
};