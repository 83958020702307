
import WithModel from '../mixins/withModel';
import { mixins, Options } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class inputCodeConfirmation extends mixins(WithModel) {
    
    @Prop({
        default: 0
    }) tabIndex;

    @Prop({
        default: false
    }) readonly: boolean;

    @Prop() codeLength: number;

    @Prop() step: number;
    @Prop() min: number;
    @Prop() max: number;

    completeCode: string = "";

    mounted(){
        var result = this.localValue;

        if(result.length == this.codeLength)
            return result;
        
        for(var i = 0; i < this.codeLength - result.length; i++)
            result += " ";
        
        this.completeCode = result;
    }

    inserted(event, index){
        if(event.inputType == "insertFromPaste")
            return;

        var value = event.data;

        var temp = ""
        if(!value || !value.replace(/[^0-9]+/g, '')){
            temp = this.replaceAt(this.completeCode, index, " ");
        } else {
            temp = this.replaceAt(this.completeCode, index, value);
        }

        this.localValue = temp;
        this.completeCode = "";

        this.$nextTick( () => {
            this.completeCode = temp;
        })

        if(value && value.replace(/[^0-9]+/g, '') && index < this.codeLength - 1)
            (<any>this.$refs["input_" + (index + 1)]).focus();
    }

    replaceAt(text, index, replacement) {
        return text.substring(0, index) + replacement + text.substring(index + 1);
    }

    pasted(event){
        var clipboardData = event.clipboardData || event.originalEvent.clipboardData || (<any>window).clipboardData;
        var pastedData = clipboardData.getData('text');

        var temp = pastedData.replace(/[^0-9]+/g, '');

        for(var i = 0; i < this.codeLength - temp.length; i++)
            temp += " ";

        if(temp.length > this.codeLength)
            temp = temp.substr(0, this.codeLength);

        this.localValue = temp;
        this.completeCode = "";

        setTimeout(() => {
            this.completeCode = temp;
        }, 100);
    }

}
