
import WithModel from '../mixins/withModel';
import { mixins, Options } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class CheckboxField extends mixins(WithModel) {

    @Prop() labelKey: string;
    @Prop() color: string;
    @Prop() labelValue: string;
    @Prop() readonly: boolean;
    @Prop() tabIndex: number;
    @Prop() checkboxIsSelected: boolean;
}
