import { LocalizationServices } from "./services/LocalizationServices";

export const date = function (el, binding, vnode, prevVnode) {
    if (el.tagName.toLowerCase() != "input") {
        console.error('v-date directive must be used on input tags. use type="date"');
        return;
    }
    if (el.getAttribute("type") != "date") {
        console.error('v-date directive must be used on input with type="date"');
        return;
    }

    let modelDirectiveIndex = vnode.dirs.findIndex(x => x.value && (isValidDate(x.value) || isValidStringDate(x.value)));
    if(modelDirectiveIndex == -1){
        console.error('v-date directive requires v-model on element');
        return;
    }
    let val = vnode.dirs[modelDirectiveIndex].value;
    if(val){
        (<HTMLInputElement>el).value = validateDate(val);
        var item = (<HTMLInputElement>el).attributes.getNamedItem("placeholder");

        if(item){
            item.value = "";
            (<HTMLInputElement>el).attributes.setNamedItem(item);
        }
    }
};
function validateDate(inputDate: string){
    var stringDate = inputDate.toString();
    let date = new Date(stringDate);
    if(isValidDate(date)){
        let years = date.getFullYear().toString();
        while(years.length < 4){
            years = "0" + years;
        } 
        let months = (date.getMonth() + 1).toString();
        if(months.length < 2) months = "0" + months;
        let days = date.getDate().toString();
        if(days.length < 2) days = "0" + days;

        return years +  "-" + months + "-" + days;
    }
}

function isValidDate(d: any) {
    return d instanceof Date && !isNaN(<any>d);
}

function isValidStringDate(d: any) {
    return typeof(d) == typeof("") && Date.parse(d);
}

export let localizedPlaceholderDirective = function (el, binding, vnode, prevVnode) {

    var key = binding.value["key"];
    var value = binding.value["value"];

    if (!key || !value) {
        console.error('v-localizedPlaceholder need key and value');
        return;
    }

    var prevText = binding.value["prevText"];
    var afterText = binding.value["afterText"];

    var text = LocalizationServices.getLocalizedValue(key, value, afterText, prevText);

    (<any>el).placeholder = text;
}