
import { mixins, Options, Vue } from 'vue-class-component';
import * as OM from '@/Model';
import * as VM from '@/viewModel';
import { Prop } from 'vue-property-decorator/lib/decorators/Prop';
import WithModel from '../standard/formFieldPlugin/mixins/withModel';

@Options({
    components: {
    }
})
export default class HeadColumn extends Vue {

    @Prop({ default: "" }) param: string;
    @Prop({ default: true }) sortable: boolean;

    mounted(){

    }
}
