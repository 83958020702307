
import router from '@/router';
import { LoginServices } from '@/services/LoginServices';
import { StorageServices } from '@/services/StorageServices';
import { Options, Vue } from 'vue-class-component';

@Options({
  components: { },
})
export default class Navbar extends Vue {

	isMenuOpen: boolean = true;
    
    logout() {
        LoginServices.logout();
    }

}
