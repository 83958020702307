import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Options({})
export default class WithModel extends Vue {
    
    @Prop() modelValue: any;

    localValue: any = null;

    @Watch('modelValue')
    onModelValueChange(next, prev){
        this.localValue = next;
    }

    @Watch("localValue")
    emitModel(val: any){
        this.$emit("update:modelValue", val);
    }
    
    created(){
        this.localValue = this.modelValue;
    }


}