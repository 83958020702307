import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Options({})
export default class WithModel extends Vue {
    
    @Prop() modelValue: any;

    localValue: any = null;

    @Watch('modelValue')
    onModelValueChange(next, prev){
        this.localValue = next;
    }

    created(){
        this.localValue = this.modelValue;
    }

    emitModel(val: any){
        this.$emit("update:modelValue", val);
    }

}