
import { Prop } from 'vue-property-decorator';
import { Options, prop, Vue } from 'vue-class-component';
import store from '@/store';
import * as OM from "@/Model";
import { LocalizationServices } from '@/services/LocalizationServices';

@Options({})
export default class LocalizedTextArea extends Vue {

    @Prop({
        type: [OM.LocalizedValue, Object],
        default: function () { return { values: {} } }
    }) modelValue: OM.LocalizedValue;

    @Prop({
        default: 'text'
    }) type: string;

    @Prop({
        default: ''
    }) placeholder: string;

    @Prop({
        default: 1
    }) tabIndex: number;

    @Prop({
        default: ""
    }) labelKey: string;

    @Prop({
        default: ""
    }) labelValue: string;

    locales = null;
    loaded = false;

    created() {
        if (!this.modelValue)
            this.modelValue = new OM.LocalizedValue();

        if (!this.modelValue.values)
            this.modelValue.values = {};

        this.locales = store.state.consts.enabledLanguages;

        this.loaded = true;
    }
    //selectedLocale: string = this.$localizationService.getLanguage();

    setInputLocale(language: string) {
        LocalizationServices.SetSelectedLanguage(language);
    }

    get selectedLocale() {
        return store.state.selectedLanguage;
    }

    get selectedInput() {
        // var keys = Object.keys(this.modelValue.values);

        // if (keys.indexOf(this.selectedLocale) == -1)
        //     this.modelValue.values[this.selectedLocale] = "";

        // return this.modelValue.values[this.selectedLocale];
        var input = this.modelValue.values[this.selectedLocale];
        if (!input) {
            this.modelValue.values[this.selectedLocale] = "";
        }
        return this.modelValue.values[this.selectedLocale];
    }

    set selectedInput(value: string) {
        this.modelValue.values[this.selectedLocale] = value;
    }

}
