
import { Options, Vue } from 'vue-class-component';
import * as OM from '@/Model';
import * as VM from '@/viewModel';
import { Prop, Watch } from 'vue-property-decorator';

@Options({
    components: {
    }
})
export default class TableFilter extends Vue {

    @Prop() pagedRequest: OM.PagedRequestVM;
    @Prop() tableRoot: any;
    @Prop() totalItems: number;

    localPagedRequest: OM.PagedRequestVM = new OM.PagedRequestVM();
    pages: number[] = [];
    lowerPages: number = 0;
    upperPages: number = 0;

    @Watch("totalItems")
    @Watch("pagedRequest", { deep: true})
    requestChanged(){
        this.localPagedRequest = this.pagedRequest;
        this.calcPagination();
    }

    get totalPages(){
        return Math.ceil((this.totalItems) / this.localPagedRequest.paging.itemsPerPage);
    }
    get selectedPage(){
        return this.localPagedRequest.paging.currentPage;
    }

    created(){
        this.localPagedRequest = this.pagedRequest;
        this.calcPagination();
    }

    mounted(){
        this.$nextTick( () => {
            var tableRoot = <any>this.$refs.table_filter;
            this.$emit("update:tableRoot", tableRoot);
            let headTds = tableRoot.querySelectorAll('.head_list .column');
            let headSpans = tableRoot.querySelectorAll('.head_list .column span');

            for(var i = 0; i < headTds.length; i++){
                var param = headTds[i].getAttribute("param");
                if(!param)
                    return;

                if(headTds[i].getAttribute("sortable") == "true")
                    this.insertSort(headSpans[i], param);
                
                this.insertFilter(headTds[i], param);
            }
        })
    }

    insertSort(element, param){
        if(!param)
            return;
        
        element.onclick = () => {
            this.$emit("changeSort", param)
        }
    }

    insertFilter(element, param){
        if(!param)
            return;
        
        var input = document.createElement('input');
        input.type = "text";
    
        var child = element.appendChild(input);
        child.oninput = (value) => {
            var text = value.target.value;

            if(!text){
                delete this.localPagedRequest.filter[param];
                return;
            }

            this.localPagedRequest.filter[param] = [];
            this.localPagedRequest.filter[param].push(text);
            this.$emit("update:pagedRequest", this.localPagedRequest);
        }
    }

    subtractPage(){
        if(this.selectedPage > 1){
            var vm = this.localPagedRequest;
            vm.paging.currentPage--;
            this.$emit("update:pagedRequest", vm);
            this.calcPagination();
        }
    }
    addPage(){
        if(this.selectedPage < this.totalPages){
            var vm = this.localPagedRequest;
            vm.paging.currentPage++;
            this.$emit("update:pagedRequest", vm);
            this.calcPagination();
        }
    }

    setSelectedPage(index: number){
        var vm = this.localPagedRequest;
        vm.paging.currentPage = index;
        this.$emit("update:pagedRequest", vm);
    }

    calcPagination(){
        this.lowerPages = this.selectedPage - 2;
        this.upperPages = this.selectedPage + 2;

        if(this.selectedPage == 1)
            this.upperPages += 2;
        else if(this.selectedPage == 2)
            this.upperPages += 1;

        if(this.selectedPage == this.totalPages)
            this.lowerPages -= 2;
        else if(this.selectedPage == this.totalPages - 1)
            this.lowerPages -= 1;

        if(this.upperPages > this.totalPages)
            this.upperPages = this.totalPages;

        if(this.lowerPages <= 0)
            this.lowerPages = 1;

        if(this.upperPages <= 0)
            this.upperPages = 1;
            
        this.pages = [];
        for(let i = this.lowerPages; i <= this.upperPages; i++){
            this.pages.push(i);
        }
    }

    changePage(){
        this.localPagedRequest.paging.currentPage = 1;
        this.$emit('update:pagedRequest', this.localPagedRequest)
    }
}
