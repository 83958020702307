
import { mixins, Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import WithModel from '../mixins/withModel'

@Options({
    components: {
    }
})
export default class inputMail extends mixins(WithModel) {

    @Prop({
        default: 0
    }) tabIndex;

    @Prop({
        default: ""
    }) placeholderKey;

    @Prop({
        default: ""
    }) placeholderValue;

    @Prop({
        default: ""
    }) placeholderPrevText: string;

    @Prop({
        default: ""
    }) placeholderAfterText: string;

    @Prop({
        default: false
    }) readonly: boolean;

}
