
import WithModel from '../mixins/withModel';
import { mixins, Options } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({
    components: {
    }
})
export default class InputFile extends mixins(WithModel) {

    @Prop() fileModeIcon: boolean;
    @Prop() fileName: string;

    @Prop({
        default: false
    }) whiteBg: boolean;

    selectFile(){
        (<any>this.$refs.input).click();
    }
    
    onFileSelect(ev){
        let file = ev.target.files[0];
        this.emitModel(file);
    }        
}   
