function readFromLocalStorage<T>(lsName: string): T | null {
    let data = window.localStorage.getItem(lsName);
    if(data){
        return JSON.parse(data);
    } else {
        return null;
    }
}

export function setOpTableFilters(key: string, filters: any){
    window.localStorage.setItem('_op_' + key, JSON.stringify(filters));
}
export function getOpTableFilters(key: string){
    return readFromLocalStorage<any>('_op_' + key);
}