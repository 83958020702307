
import { mixins, Options, Vue } from 'vue-class-component';
import tinymce, { Editor } from  'tinymce';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';
import 'tinymce/models/dom/model.js';
import 'tinymce/skins/ui/oxide/skin.min.css'

import 'tinymce/plugins/code';
import 'tinymce/plugins/image';

import { Prop, Watch } from 'vue-property-decorator';
import WithModel from '../mixins/withModel';

@Options({
    components: {
    }
})
export default class TinyMceView extends mixins(WithModel) {

    @Prop({
        default: ""
    }) imageEndpoint: string;

    @Prop({
        default: ""
    }) fileType: string;

    @Prop({
        default: false
    }) readonly: boolean;

    content = "";
    editor: Editor = null;
    cTinyMce = null;
    checkerTimeout= null;
    isTyping = false;

    randomIdentifier: string = "";

    toolbar1 = 'undo redo | formatselect | bold italic  strikethrough  forecolor backcolor | link | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent  | removeformat';
    plugins = 'textpattern paste code'; //image

    created(){
        this.content = this.localValue;
        this.randomIdentifier = "n" + (Math.floor(Math.random() * (99999999999 - 10000000000) + 10000000000)).toString();
    }
    
    @Watch("modelValue")
    changeContent(){
        if(!this.editor)
            return;

        if(this.localValue != this.editor.getContent())
            this.editor.setContent(this.localValue)
    }

    mounted(){
        var options = {
            strict_loading_mode : true,
            selector: '#' + this.randomIdentifier,
            skin: false,
            toolbar1: this.toolbar1,
            plugins: this.plugins,
            init_instance_callback : this.initEditor,
            image_title: false,
            images_upload_url: '',
            file_picker_types: '',
            forced_root_block : "false",
            entity_encoding: "raw"
        }

        if(this.imageEndpoint){
            options.image_title = true;
            options.images_upload_url = this.imageEndpoint;
            options.file_picker_types = this.fileType;
        }

        tinymce.init(<any>options);
    }

    initEditor(editor) {
        this.editor = editor;
        editor.on('KeyUp', (e) => {
            this.submitNewContent();
        });
        editor.on('Change', (e) => {
            if(this.editor.getContent() !== this.localValue){
                this.submitNewContent();
            }
            this.$emit('editorChange', e);
        });
        editor.on('init', (e) => {
            editor.setContent(this.content);
            this.$emit('input', this.content);
        });
        if(this.readonly){
            this.editor.mode.set('readonly');
        } else {
            this.editor.mode.set('design');
        }

        this.$emit('editorInit', editor);
    }

    submitNewContent(){
        this.isTyping = true;
        if(this.checkerTimeout !== null)
            clearTimeout(this.checkerTimeout);
            this.checkerTimeout = setTimeout(()=>{
                this.isTyping = false;
            }, 300);

        this.$emit('update:modelValue', this.editor.getContent());
    }
   
}
