
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Breadcrumb } from '@/viewModel';

@Options({
  components: { },
})
export default class Breadcrumbs extends Vue {
    
    @Prop({
        default: () => [],
    }) lista: Breadcrumb[];

    created(){
    }

    mounted() {
	}

}
