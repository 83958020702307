import { vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createBlock("input", {
    type: "time",
    "onUpdate:modelValue": [
      _cache[1] || (_cache[1] = ($event: any) => (_ctx.localValue = $event)),
      _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.emitModel && _ctx.emitModel(...args)))
    ],
    tabindex: _ctx.tabIndex,
    readonly: _ctx.readonly
  }, null, 8, ["tabindex", "readonly"])), [
    [_vModelText, _ctx.localValue]
  ])
}