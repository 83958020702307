import { vModelCheckbox as _vModelCheckbox, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "d-flex border-0" }
const _hoisted_2 = {
  key: 2,
  class: "ms-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _withDirectives(_createVNode("input", {
      class: "flex_shrink_0 mt-1",
      type: "checkbox",
      tabindex: _ctx.tabIndex,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.localValue = $event)),
      readonly: _ctx.readonly
    }, null, 8, ["tabindex", "readonly"]), [
      [_vModelCheckbox, _ctx.localValue]
    ]),
    (_ctx.color)
      ? (_openBlock(), _createBlock("div", {
          key: 0,
          class: "ball ms-3",
          style: 'background-color:' + _ctx.color
        }, null, 4))
      : _createCommentVNode("", true),
    (_ctx.labelKey)
      ? (_openBlock(), _createBlock(_component_localized_text, {
          key: 1,
          class: "ms-2 my-auto fev_text5",
          localizedKey: _ctx.labelKey,
          text: _ctx.labelValue
        }, null, 8, ["localizedKey", "text"]))
      : _createCommentVNode("", true),
    (!_ctx.labelKey && _ctx.labelValue)
      ? (_openBlock(), _createBlock("span", _hoisted_2, _toDisplayString(_ctx.labelValue), 1))
      : _createCommentVNode("", true)
  ]))
}