import { RouteRecordRaw } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/app-configuration',
            name: 'app configuration',
            component: () => import("./views/appConfiguration.vue")
        }
    ];
};