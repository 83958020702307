
import { Prop } from 'vue-property-decorator';
import { mixins, Options, Vue } from 'vue-class-component';
import * as OM from '@/Model';
import { LocalizationServices } from '@/services/LocalizationServices'
import store from '@/store';
import tinyMceView from "./TinyMceView.vue";
import WithModel from '../mixins/withModel';

@Options({
    components: {
        tinyMceView
    }
})
export default class LocalizedTinymce extends mixins(WithModel) {
    
    @Prop({
        default: ""
    }) titleKey: string;

    @Prop({
        default: ""
    }) titleValue: string;

    @Prop({
       default: ''
    }) placeholder: string;

    @Prop({
        default: 1
    }) tabIndex: number;

    locales = null;

    created(){
        this.locales = store.state.consts.enabledLanguages;
    }
    //selectedLocale: string = LocalizationServices.GetLanguage();

    setInputLocale(language: string){
        LocalizationServices.SetSelectedLanguage(language);
    }

    get selectedLocale(){
        return store.state.selectedLanguage;
    }

    get selectedInput(){
        var keys = Object.keys(this.localValue.values);

        if(keys.indexOf(this.selectedLocale) == -1)
            this.localValue.values[this.selectedLocale] = "";

        return this.localValue.values[this.selectedLocale];
    }

    set selectedInput(value: string){
        this.localValue.values[this.selectedLocale] = value;
    }

}
