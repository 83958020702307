import { h } from 'vue';
import { RouteRecordRaw, RouterView } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/receipt',
            component: { render: () => h(RouterView) },
            children: [
                {
                    path: '/receipt',
                    name: 'receipt',
                    component: () => import("./views/receipt.vue")
                }
            ]
        }
    ];
};