import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import { beginModule } from '@/views/begin';
import { dashboardModule } from '@/views/dashboard';
import { usersModule } from '@/views/users';
import { eventsModule } from '@/views/events';
import { dictionaryModule } from '@/views/dictionary';
import { challengeModule } from '@/views/challenge';
import { rankingModule } from '@/views/ranking';
import { receiptModule } from '@/views/receipt';
import { appConfigurationModule } from '@/views/appConfiguration';
import store from '@/store';

const routes: Array<RouteRecordRaw> = [
    ...beginModule.routes(),
    {
        path: '/',
        component: () => import("../views/layout.vue"),
        beforeEnter: (to, from) => {
            if(!store.state.loggedUser.identifier)
                return false
        },
        children: [
            ...dashboardModule.routes(),
            ...usersModule.routes(),
            ...eventsModule.routes(),
            ...dictionaryModule.routes(),
            ...challengeModule.routes(),
            ...rankingModule.routes(),
            ...receiptModule.routes(),
            ...appConfigurationModule.routes()
        ]
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router