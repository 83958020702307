import { vModelText as _vModelText, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_date = _resolveDirective("date")!
  const _directive_localizedPlaceholder = _resolveDirective("localizedPlaceholder")!

  return _withDirectives((_openBlock(), _createBlock("input", {
    type: "date",
    "onUpdate:modelValue": [
      _cache[1] || (_cache[1] = ($event: any) => (_ctx.localValue = $event)),
      _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.emitModel && _ctx.emitModel(...args)))
    ],
    tabindex: _ctx.tabIndex,
    readonly: _ctx.readonly,
    class: { no_value : !_ctx.localValue}
  }, null, 10, ["tabindex", "readonly"])), [
    [_vModelText, _ctx.localValue],
    [_directive_date],
    [_directive_localizedPlaceholder, {key: _ctx.placeholderKey, value: _ctx.placeholderValue, prevText: _ctx.placeholderPrevText, afterText: _ctx.placeholderAfterText }]
  ])
}