
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Breadcrumb } from '@/viewModel';

@Options({
  components: { },
})
export default class AddButton extends Vue {
    
    created(){
    }

    mounted() {
	}

}
