
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import * as OM from "@/Model";

@Options({
    components: {
    }
})
export default class SelectModal extends Vue {

    @Prop() identifierSelected: string;
    @Prop() list: OM.TextIdentifier[];
    @Prop() confirm: (identifier: string) => void;

    localValue: string = "";
    localList: OM.TextIdentifier[] = [];
    search: string = "";

    created(){
        this.localValue = this.identifierSelected;
        this.localList = [...this.list]
    }

    get filteredList(){
        var ordered = this.localList;
        
        if(this.identifierSelected){
            var found = ordered.find( x => x.identifier == this.identifierSelected);

            if(!found)
                return ordered;

            var index = ordered.indexOf(found);
            var removed = ordered.splice(index, 1)[0];
            ordered.unshift(removed);
        }

        if(!this.search)
            return ordered;

        var ordered = ordered.filter( x => x.text.trim().toLowerCase().indexOf(this.search.trim().toLowerCase()) != -1);

        return ordered;
    }

    select(identifier: string){
        this.confirm(identifier);
    }

    get isDisabled(){
        return !this.identifierSelected;
    }
}
