
import store from '@/store';
import { Options, Vue } from 'vue-class-component';

@Options({
})
export default class SpinnerContainer extends Vue {

    get showSpinner(){
        return store.state.showSpinner > 0;
    }
}
