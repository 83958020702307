
import { Options, mixins } from 'vue-class-component';
import WithModel from '@/mixins/withModel';
import { Prop, Watch } from 'vue-property-decorator';

@Options({
    components: {
    }
})
export default class ToggleSwitch extends mixins(WithModel)  {

  @Prop() activeText: string;
  @Prop() inactiveText: string;

  id: string = "";

  created(){
    this.id = Math.random().toString();
  }

  @Watch("localValue")
  changed(event){
    if(this.localValue != this.modelValue)
      this.emitModel(this.localValue)
  }
}
