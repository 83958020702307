
import { Options, Vue } from 'vue-class-component';

@Options({})
export default class MiniSpinner extends Vue {

    mounted() {
    }

}
